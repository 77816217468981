import * as styles from "./blog-post.module.css";
import commentBox from "commentbox.io";

import { differenceInDays, formatDistance, format } from "date-fns";
import AuthorList from "./author-list";
import Container from "./container";
import PortableText from "./portableText";
import React, { useEffect } from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function BlogPost(props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt, slug } =
    props;

  const graphCommentId = "be-curious-io";
  useEffect(() => {
    // const removeCommentBox = commentBox("5724555825905664-proj");
    window.gc_params = {
      graphcomment_id: graphCommentId,
      fixed_header_height: 0,
      // behaviour: {
      //   // uid: getBlogUrl(publishedAt, props.slug.current),
      //   uid: slug.current,
      // },
    };
    (function () {
      const script = document.createElement("script");
      script.src =
        "https://graphcomment.com/js/integration.js?" +
        Math.round(Math.random() * 1e8);
      script.type = "text/javascript";
      script.async = true;

      const comments = document.getElementById("graphcomment");
      if (comments) {
        comments.appendChild(script);
      } else {
        console.log("no comments");
      }

      // This function will get called when the component unmounts
      // To make sure we don't end up with multiple instances of the comments component
      // return () => {
      //   const comments = document.getElementById(graphCommentId);
      //   if (comments) {
      //     comments.innerHTML = "";
      //   } else {
      //     console.log("no comments");
      //   }
      // };
    })();
  }, [slug]);

  return (
    <article className={styles.root}>
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit("crop")
              .auto("format")
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
          {/*<div className="commentbox" />*/}
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? formatDistance(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "MMMM Mo, yyyy")}
              </div>
            )}
            {authors && <AuthorList items={authors} title="Authors" />}
            {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
          <div id="graphcomment" />
        </div>
      </Container>
    </article>
  );
}

export default BlogPost;
